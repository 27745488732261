import * as React from 'react'
import * as styles from '../../assets/styles.module.scss'

interface ProjectInterface {
    title: string
    slug: string
    image: string
    description: string
    labels: any
}

interface Props {
    key: number
    item: {
        title: string
        slug: string
        image: string
        description: string
        labels: any
    }
}
interface State {
    item: ProjectInterface
}

export default class Grid extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)
    }

    componentWillMount() {
        this.setState({
            item: this.props.item
        })
    }

    public _Render() {
        const { item } = this.state

        return item && (
            <section className={styles.Project}>
                {/* <a href={`/work/case-studies/` + this.props.item.slug}> */}
                    <img alt={item.title} src={item.image} />
                    <h2>{item.title}</h2>
                    <div className={styles.Tags}>
                        {item.labels.map((i: any) => {
                            return (
                                <span>{i}</span>
                            )
                        })}
                    </div>
                    <p>
                        {item.description}
                    </p>
                {/* </a> */}
            </section>
        )
    }

    public render() {
        return this._Render() || (<div></div>)
    }
}